<template>
  <div class="edit-note">
    <h1 class="section-header">Note</h1>
    <textarea v-model="editNote" class="mt-3" rows="7"></textarea>
    <div class="d-flex justify-content-end mt-4">
      <app-button class="btn--blue mr-2" @click="save">Spara</app-button>
      <app-button class="btn--transparent" @click="$emit('cancel')">Avbryt</app-button>
    </div>
  </div>
</template>

<script>
import {errorNotification} from "@/utils/translations";
import tripsHttp from '@/http/trips'

export default {
  props: {
    note: {
      type: [String, null],
      default: ''
    },
    trip: {
      type: Object
    }
  },
  data() {
    return {
      editNote: this.note
    }
  },
  methods: {
    async save() {
      try {
        await tripsHttp.addTripNote(this.trip.tripId, this.trip.boxId, this.editNote);
        this.$emit('noteAdded', this.editNote);
      } catch (e) {
        this.$notify.error(errorNotification);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-note {
  textarea {
    width: 100%;
    border-radius: 20px;
    background: var(--color-gray-light);
    font-family: inherit;
    padding: 15px 23px;
    box-sizing: border-box;
    border: 1px solid transparent;

    &:focus {
      outline: none;
      border: 1px solid var(--color-blue);
    }
  }
}
</style>
