import axios from 'axios';

export default ({
  getTrips(licencePlate, date, page) {
    return axios.get('/trips', {params: {limit: 10, skip: page, dateFrom: date[0], dateTo: date[1], vehicles: licencePlate}})
    // return axios.get(`/vehicles/${licencePlate}/trips?dateFrom=${date[0]}&dateTo=${date[1]}&limit=10&skip=${page}`)
  },

  changeTripCategory(tripId, boxId, category) {
    return axios.put(`/boxes/${boxId}/trips/${tripId}/category/${category}`);
  },

  addTripNote(tripId, boxId, note) {
    return axios.put(`/boxes/${boxId}/trips/${tripId}/notes`, note)
  },


})
