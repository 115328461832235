<template>
  <div class="row">
    <div class="col-md-7">
      <div class="trip-details-row">
        <div class="label">Förare</div>
        <div class="value" v-if="trip.user">{{trip.user.firstname}} {{trip.user.lastname}}</div>
      </div>
      <div class="trip-details-row">
        <div class="label">Mätarställning start</div>
        <div class="value">{{trip.startMileage | round | thousandSpace}} km</div>
      </div>
      <div class="trip-details-row">
        <div class="label">Mätarställning stopp</div>
        <div class="value">{{trip.endMileage | round | thousandSpace}} km</div>
      </div>
      <div class="trip-details-row">
        <div class="label">Startpunkt</div>
        <div class="value">{{trip.startAddress}}</div>
      </div>
      <div class="trip-details-row">
        <div class="label">Slutpunkt</div>
        <div class="value">{{trip.endAddress}}</div>
      </div>
      <div class="trip-details-row">
        <div class="label">TripID</div>
        <div class="value">{{trip.tripId}}</div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="trip-details-row">
        <div class="label" style="width: 100px">Anteckning</div>
        <div class="value" style="width: 80%">
          {{trip.notes.content}}
          <div class="trip-images">
            <drivelog-lightbox :start="galleryStartIndex" :show-gallery="showImagePreview" @close="showImagePreview = false" v-if="trip.notes.attachments && showImagePreview" :slides="trip.notes.attachments"/>
            <img :src="image.contentUrl" @click="openGallery(index)" v-for="(image, index) in trip.notes.attachments" :key="image.id" class="trip-image" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DrivelogLightbox from "@/components/drivelog/DrivelogLightbox";
export default {
  components: {DrivelogLightbox},
  props: ['trip'],
  data() {
    return {
      showImagePreview: false,
      galleryStartIndex: 0
    }
  },
  filters: {
    fixedOneDecimal(value) {
      return value ? value.toFixed(1) : ''
    },
    round(value) {
      return value ? Math.round(value) : ''
    }
  },
  methods: {
    openGallery(index) {
      this.galleryStartIndex = index;
      this.showImagePreview = true;
    },
    closeGallery() {
      this.showImagePreview = false;
      this.galleryStartIndex = 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.trip-details-row {
  @include flexbox;
  margin-bottom: 8px;

  .label {
    font-weight: 700;
    width: 160px;
    font-size: 14px;
  }
  .value {
    font-size: 14px;
  }
}
.trip-images {
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  grid-gap: 5px;

  img {
    width: 100%;
    height: 70px;
    border-radius: 5px;
    cursor: pointer;
    object-fit: cover;
  }
}
</style>
