<template>
  <div class="drivelog">
    <drivelog-filter @filterChanged="onFilterChanged($event)"></drivelog-filter>
    <drive-log-table
        :total-pages="totalPages"
        @pageChanged="onPageChanged"
        :current-page="currentPage" :trips="trips" class="mt-5"></drive-log-table>
  </div>
</template>

<script>
import DrivelogFilter from "@/components/drivelog/DrivelogFilter";
import DriveLogTable from "@/components/drivelog/DriveLogTable";
import tripsHttp from '@/http/trips'
import {calculateDistance} from "@/utils/drivelog";

export default {
  components: {DriveLogTable, DrivelogFilter},
  data() {
    return {
      trips: [],
      totalPages: 0,
      currentPage: 1,
      box: '19171858488',
      date: ['2021-05-07','2021-05-20']
    }
  },
  methods: {
    async onFilterChanged(filters) {
      this.currentPage = 1;
      const {vehicle, date} = filters;
      await this.getTrips(vehicle, date);
    },
    async onPageChanged(page) {
      this.currentPage = page;
      await this.getTrips(this.box, this.date, page);
    },
    async getTrips(box, date, page = 1) {
      try {
        this.box = box;
        this.date = date;

        const {trips, totalCount} = await tripsHttp.getTrips(box, date, page - 1);
        this.trips = trips.map(item => ({
              ...item,
              expanded: false,
              distance: calculateDistance(item.distance)
            })
        );

        this.totalPages = Math.ceil(totalCount / 10);
      } catch (e) {}
    }
  },
  async created() {
    // await this.getTrips('19171858488', ['2021-05-07','2021-05-20']);
  }
}
</script>

<style scoped>

</style>
