<template>
  <div class="notes-images-modal">
    <div class="close-gallery" @click="$emit('close')">
      <app-icon name="cross" color=""/>
    </div>
    <splide
        :options="options"
        ref="fullScreen"
    >
      <splide-slide v-for="slide in slides" :key="slide.id">
        <img class="slide-img" :src="slide.contentUrl" alt="slide.alt">
      </splide-slide>
    </splide>
  </div>
</template>

<script>
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
export default {
  props: ['slides' ,'showGallery', 'start'],
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      options: {
        // type: 'loop',
        start: this.start,
        perPage: 1,
        perMove: 1,
        gap: '1rem',
        fixedWidth: '100%',
        keyboard: true,
        pagination: false,
      }
    }
  },
  watch: {
    showGallery() {
      if (value) {
        document.addEventListener('keydown', this.handleKeyboardInput)
      } else {
        document.removeEventListener('keydown', this.handleKeyboardInput)
      }
    }
  },
  methods: {
    handleKeyboardInput(e) {
      switch (e.key) {
        case 'Escape':
          this.showFullScreen = false;
          return;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.close-gallery {
  position: absolute;
  top: 25px;
  right: 40px;
  cursor: pointer;
  z-index: 11000;
}
</style>

<style lang="scss">
.close-gallery {
  svg {
    cursor: pointer;
    transform: scale(1.5);
  }
  path {
    fill: #ffffff;
  }
}

.notes-images-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0000008c;
  padding: 30px;

  .splide__slide {
    display: flex;
    justify-content: center;
  }

  .slide-img {
    height: auto;
    cursor: pointer;
    max-height: 80vh;
    object-fit: contain;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .splide__arrows {
    path {
      fill: #ffffff;
    }
  }
}
</style>
