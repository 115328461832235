<template>
  <div>
    <h1 class="section-header">Reselogg</h1>
    <div class="box box--outline mt-3">
      <app-table :head="tableHead">
        <template v-for="(trip, index) in trips">
          <tr :key="index" :class="{'expanded': trip.expanded}" @click="trip.expanded = !trip.expanded">
            <td>{{trip.licencePlate}}</td>
            <td>{{trip.duration | duration}}</td>
            <td>{{trip.distance | fixedOneDecimal}}km</td>
            <td>{{trip.startDateTime | unixToDate}}</td>
            <td>{{trip.endDateTime | unixToDate}}</td>
            <td>{{trip.startAddress | address}}</td>
            <td>{{trip.endAddress | address}}</td>
            <td>
              <div class="d-flex">
                <app-toggle @click.native.stop @input="changeTripCategory(trip, $event)" :value="categoryValue(trip.category)" left-value="Privat" right-value="Tjänst"></app-toggle>
                <app-icon  style="cursor: pointer" class="mx-3" :name="noteIcon(trip.notes)"></app-icon>
                <app-icon @click.native.stop="trip.expanded = !trip.expanded" class="arrow ml-3" :class="{'rotate-arrow': !trip.expanded}" name="chevron-down"/>
              </div>
            </td>
          </tr>
          <tr v-if="trip.expanded">
            <td colspan="9">
              <drive-log-details :trip="trip"></drive-log-details>
            </td>
          </tr>
        </template>
      </app-table>

      <app-modal @close="closeEditNote" v-if="showNoteModal">
        <edit-note @noteAdded="onNoteAdded" :trip="editTrip" @cancel="closeEditNote" :note="editNote"></edit-note>
      </app-modal>

      <div class="d-flex justify-content-end">
        <paginate
            class="mt-4"
            :page-count="totalPages"
            :prev-text="arrow"
            :next-text="arrow"
            prev-class="prev"
            next-class="next"
            :value="currentPage"
            :click-handler="onPageChanged"
            :container-class="'vue-pagination'">
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import AppTable from "@/components/shared/AppTable";
import AppToggle from "@/components/shared/AppToggle";
import AppIcon from "@/components/shared/AppIcon";
import DriveLogDetails from "@/components/drivelog/DriveLogDetails";
import tripsHttp from '@/http/trips'
import EditNote from "@/components/drivelog/EditNote";

export default {
  components: {DriveLogDetails, AppIcon, AppToggle, AppTable, EditNote},
  props: ['trips', 'totalPages', 'currentPage'],
  data() {
    return {
      tableHead: [
        {name: 'Reg.nr'},
        {name: 'Restid'},
        {name: 'Distans'},
        {name: 'Starttid'},
        {name: 'Stopptid'},
        {name: 'Startpunkt'},
        {name: 'Slutpunkt'},
        {name: '', width: 15}
      ],
      showNoteModal: false,
      editTrip: null,
      editNote: null,
      logs: [
        {plate: 'ABC 123', duration: '0h19m', distance: '104km', startAddress: 'Storvägen 18, Västerås', endAddress: 'Storvägen 2, Stockholm', isPrivate: true},
        {plate: 'ABC 123', duration: '0h19m', distance: '104km', startAddress: 'Storvägen 18, Västerås', endAddress: 'Storvägen 2, Stockholm', isPrivate: true},
        {plate: 'ABC 123', duration: '0h19m', distance: '104km', startAddress: 'Storvägen 18, Västerås', endAddress: 'Storvägen 2, Stockholm', isPrivate: false},
        {plate: 'ABC 123', duration: '0h19m', distance: '104km', startAddress: 'Storvägen 18, Västerås', endAddress: 'Storvägen 2, Stockholm', isPrivate: true},
      ]
    }
  },
  methods: {
    onPageChanged(page) {
      this.$emit('pageChanged', page);
    },
    categoryValue(value) {
      return !(!value || value === 'business');
    },
    async changeTripCategory(trip, value) {
      const category = value ?  'private' : 'business';
      await tripsHttp.changeTripCategory(trip.tripId, trip.boxId, category);
      trip.category = category;
    },
    openEditNote(trip) {
      this.editTrip = trip;
      this.editNote = trip.notes;
      this.showNoteModal = true;
    },
    closeEditNote() {
      this.editTrip = null;
      this.editNote = null;
      this.showNoteModal = false;
    },
    onNoteAdded(note) {
      const trip = this.trips.find(item => item.tripId === this.editTrip.tripId);
      trip.notes = note;
      this.closeEditNote();
      this.$notify.success('Din anteckning sparades!')
    },
    noteIcon(note) {
      return note.content || note.attachments.length > 0 ? 'NoteNotification-Active' : 'NoteNotification'
    }
  },
  computed: {
    arrow() {
      return `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.25 8.625L12 15.375L18.75 8.625" stroke="#161818" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `
    }
  },
  filters: {
    address(value) {
      if(value) return value.substring(0,12) + '...';
      return '-'
    },
    fixedOneDecimal(value) {
      return value ? value.toFixed(1) : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.arrow {
  cursor: pointer;
  transition: all 0.2s;
}
.rotate-arrow {
  transform: rotate(90deg);
}
.expanded {
  td {
    border-bottom: none !important;
  }
}
</style>

<style lang="scss">
.vue-pagination {
  display: inline-flex;
  list-style: none;
  background: #f3f5f9;
  padding-left: 0;
  padding: 5px 10px;
  border-radius: 30px;
  margin: 0;

  li {
    width: 32px;
    height: 32px;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);

    a {
      font-weight: 600;
      color: var(--color-gray-blueish);
      font-size: 14px;
    }
  }

  .active {
    background: var(--color-blue);
    border-radius: 50%;

    a {
      color: white !important;
    }
  }
  .prev, .next {
    a {
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
    }
  }
  .prev {
    padding-right: 12px;
  }
  .next {
    padding-left: 12px;
  }
  .prev svg {
    transform: rotate(90deg);
  }
  .next svg {
    transform: rotate(-90deg);
  }
}
</style>
